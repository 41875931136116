module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lionshub.org"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:400,500,700","Work Sans:700","Fredoka One"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146235213-1","head":false},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
